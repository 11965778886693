<template>
  <div id="questions" class="questions main-block">
    <div class="question-wave-top"></div>
    <div class="container h1-margin-bottom--large">
      <h1>You have a <span class="text-blue">question.</span> We have an
        <span class="text-blue">answer.</span></h1>
      <div class="question-group">
      </div>
      <div class="row">
        <div class="col-12 col-md-10 col-xl-10 offset-md-1 offset-xl-1">
          <div class="questions-wrap">
            <div class="questions__block" v-for="questionBlock in questions" :key="questionBlock.id">
              <div class="questions__block-name">{{questionBlock.name}}</div>
              <div class="questions__item text-start" v-for="(question, index) in questionBlock.questions" :key="index">
                <div class="question collapsed" data-bs-toggle="collapse"
                    :data-bs-target="'#answer_' + index">{{ question.question }}
                </div>
                <div v-html="question.answer" class="answer collapse" :id="'answer_' + index">
                  <div>{{ question.answer }}</div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
    <div class="question-wave-bottom"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("getQuestions");
  },
  computed: {
    questions() {
      return this.$store.state.questions;
    },
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
}
</script>