<template>
  <div class="bg-hero">  
    <div class="container-xl">
      <div class="row">
        <div class="col-md-6 text-start">
          <div class="hero-text-container">

            <h1 class="margin-bottom--large">
              <span class="text-blue">Approvee.ly</span>
               - the future of creative management!
            </h1>
            <div class=" text weight-500 border-blue margin-bottom--xl">
              Approvee.ly is an online platform where creative agencies and their clients can share, comment, and accept creative materials easily.
            </div>

            <div class="margin-bottom--large">
              <div class="margin-bottom--small d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text weight-500">2x faster approval process</div>
              </div>
              <div class="margin-bottom--small d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class=" text weight-500">25% less comment rounds</div>
              </div>
              <div class="margin-bottom--small d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class=" text weight-500">100% less emails</div>
              </div>
            </div>

            <div class="d-flex justify-content-md-center hero-button-container justify-content-lg-start">
              <a href="/#contact"  class="btn btn-mobile btn-primary">get in touch 
                <img class="icon-chev" src="../assets/icons/chevron-right.svg"/>
              </a>            
            </div>

          </div>
        </div>
        <div class="col-md-6 hero-img-wrap">
          <img class="hero-img" src="../assets/images/video-player.png"/>
          <button type="button" class="btn btn-play" @click="showVideo()">
            <img src="@/assets/icons/ic-play-white.svg" /> Play
          </button>
          <Video :video="'/video/approveely_video.mp4'" :showVideoLayer="showVideoLayer" />      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Video from '@/components/Video.vue'

export default {
  components:{
    Video
  },
  data(){
    return{
      showVideoLayer:false
    }
  },
  methods:{
    showVideo(){
      this.showVideoLayer = true
    }
  }

}
</script>
