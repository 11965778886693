<template>
  <div id="creativeWork" class=" container-xl">
    <div class=" bg-creative">
      <h1 class="h1-margin-bottom">Creative work <span class="text-blue">Reimagined.</span></h1>
      <div class="row creativeWork__row">
        <div class="col-md-6">
          <div class="customer-box vcard vcard-mobile-margin">
            <div class="d-flex margin-bottom--large align-items-center">
              <img class="margin-right--small" src="../assets/icons/building.svg"/>
              <h2>Agency</h2>
            </div>
            <img class="mw-100 margin-bottom--xl" src="../assets/images/work-img1.svg">

            <div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Manage everything at one place not to miss any client comments.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Upload and share with one click.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Enjoy the unlimited number of clients, campaigns, and boards.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Easy to use regardless of whether you run a print, banner, or TV campaign.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Assign accounts to boards to make sure everyone is notified about changes. </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="customer-box vcard">
            <div class="d-flex margin-bottom--large align-items-center">
              <img class="margin-right--small" src="../assets/icons/person.svg"/>
              <h2>Customer</h2>
            </div>
            <img class="mw-100 margin-bottom--xl" src="../assets/images/work-img2.svg">

            <div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">View your campaign creative materials in a structured, organised way.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Use pins and timestamps to leave feedback directly on the creative.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Check previous versions to make sure all your requests have been made.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Track your approved, rejected and in-progress creatives.</div>
              </div>
              <div class="margin-bottom--large d-flex">
                <img class="icon" src="../assets/icons/green-pipe.svg" alt="green-pipe"/>
                <div class="text text--creative weight-500">Download the original files without any modification or quality loss.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreativeWork"
}
</script>

<style scoped>

</style>