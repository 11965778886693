<template>
    <div>
        <div id="cookie-policy" class="cookie-policy" v-if="needCookieSettings">            
          <div class="cookie-policy__inner">                                                                                 
                <div class="text">                                               
                    <div class="text__inner">
                        We use cookies on the Website to ensure the functionality, to ease of use and security of the website, as well as to monitor user activity.<br/>
                        Clicking on "Accept all" you can allow all cookies. If you you would like to reject or customize, please click on "Options".<br/><br/>
                        <div class="link-wrap">
                            Read more: <a :href="baseUrl + 'documents/CookiePolicy_EN.pdf'" target="_blank">Cookie Policy</a>
                        </div>                            
                    </div>
                    <div class="cookies-setting" v-if="changeCookies">
                        <b-form-checkbox
                            id="cookie-necessary"
                            v-model="cookies.required"
                            name="cookie-necessary"
                            value="1"
                            unchecked-value="0"
                            disabled
                        >                            
                            Necessary cookies
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="cookie-statistic"
                            v-model="cookies.statistic"
                            name="cookie-statistic"
                            value="1"
                            unchecked-value="0"                            
                        >                            
                            Statistics
                        </b-form-checkbox>
                        <b-form-checkbox
                            id="cookie-marketing"
                            v-model="cookies.marketing"
                            name="cookie-marketing"
                            value="1"
                            unchecked-value="0"                            
                        >
                            Marketing
                        </b-form-checkbox>                       
                    </div>
                </div>                                       
                <div class="btn-wrap">
                    <button type="button" v-if="!changeCookies" class="btn-border btn" @click="showCookieSettings()">OPTIONS</button>
                    <button type="button" v-if="changeCookies" class="btn-border btn" @click="saveCookieSettings()">save and enter</button>
                    <button type="button" class="btn-primary btn" @click="acceptCookies()">ACCEPT ALL</button>
                </div>                           
            </div>
        </div>

        <!-- EDIT COOKIE POLICY -->        
        <!-- <div class="cookie-policy--edit" v-else>
            <button type="button" class="btn btn-icon" title="Setting cookies" @click="showCookieBlock()">
               Edit cookie settings
            </button>
        </div>         -->
    </div>
</template>

<script>
export default {
    props:['changeCookieSettings'],
    data(){
        return{
            needCookieSettings: true,
            changeCookies: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
            cookies:{
                required: '1',
                marketing: '0',
                statistic: '0'
            },
            expiredDate: (60 * 60 * 24) * 30 * 6,  
            cookiesNames: {
                required: 'APPR_required',
                marketing: 'APPR_marketing',
                statistic: 'APPR_statistic'
            },
            needCookiesDelete: false          
        }
    },   
    watch:{
        changeCookieSettings(newValue){
            let _this = this

            this.needCookieSettings = newValue

            if (newValue === true){
                _this.showCookieBlock()
            }
            
            
        }
    },
    mounted(){
      var _this = this

      document.body.classList.add('noscroll')

      if ( parseInt(this.$cookies.get(_this.cookiesNames.required)) === 1 ){          
        _this.needCookieSettings = false        
        document.body.classList.remove('noscroll')
      }
      
    }, 
    methods:{
        acceptCookies(){
            let _this = this            
            
            //if we change the cookies settings - need delete all cookies before
            if (_this.needCookiesDelete){
                _this.delAllCookies()
            }
            
            // required_cookie, marketing_cookie, statistic_cookie       
            this.$cookies.set( _this.cookiesNames.required, 1, this.expiredDate);
            this.$cookies.set(_this.cookiesNames.marketing, 1, this.expiredDate);
            this.$cookies.set(_this.cookiesNames.statistic, 1, this.expiredDate);

            this.needCookieSettings = false    
            
            document.body.classList.remove('noscroll')

            //set the parent components showCookieBanner false
            this.$parent.changeCookieSettings = false
        },
        showCookieSettings(){
            this.changeCookies = true
        },
        saveCookieSettings(){
            let _this = this            

            //if we change the cookies settings - need delete all cookies before
            if (_this.needCookiesDelete){
                _this.delAllCookies()
            }

            //required cookies
            this.$cookies.set(_this.cookiesNames.required, 1, _this.expiredDate);

            //marketing cookies
            if ( parseInt( _this.cookies.marketing) === 1 ){
                this.$cookies.set(_this.cookiesNames.marketing, 1, _this.expiredDate);
            } 

            //statistic cookies
            if ( parseInt(this.cookies.statistic) === 1 ){
                this.$cookies.set(_this.cookiesNames.statistic, 1, _this.expiredDate);
            }

            this.needCookieSettings = false

            document.body.classList.remove('noscroll')         

            //set the parent components showCookieBanner false
            this.$parent.changeCookieSettings = false
            
        },
        showCookieBlock(){
            let _this = this

            this.needCookieSettings = true
            this.changeCookies = true            
            this.needCookiesDelete = true

            this.cookies.required = this.$cookies.get(_this.cookiesNames.required);
            this.cookies.marketing = this.$cookies.get(_this.cookiesNames.marketing);
            this.cookies.statistic = this.$cookies.get(_this.cookiesNames.statistic);
            

        },
        delAllCookies(){
            let _this = this            
            var allCookies = _this.$cookies.keys()            

            allCookies.forEach( cookie => {
              _this.$cookies.remove(cookie)
            })
        }                
    }
}
</script>
