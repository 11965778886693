<template>
  <div>
    <b-navbar toggleable="lg" class="bg-footer">
      <div class="container padding-top--large resp-row margin-bottom--large">
        <b-navbar-brand class="navbar-brand--footer" href="#"></b-navbar-brand>

        <b-navbar-nav class="footer-menu-container flex-row d-flex">
          <div class="resp-row d-flex">
            <b-nav-item class="text-white" href="#">Home</b-nav-item>
            <b-nav-item class="text-white" href="#creativeWork">Why us?</b-nav-item>
            <b-nav-item class="text-white" href="#powerfulCollaboration">Features</b-nav-item>
          </div>
          <div class="resp-row d-flex">
            <b-nav-item class="text-white" href="#pricing">Price</b-nav-item>
            <b-nav-item class="text-white" href="#questions">FAQ</b-nav-item>
            <b-nav-item class="text-white" href="#contact">Contact</b-nav-item>
          </div>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
      </div>
    </b-navbar>
    <div class="bg-footer">
      <div class="footer-container container justify-content-between padding-bottom--small">
        <div class="text-white copyright d-inline">Copyright <img src="../assets/icons/CopyrightIcon.svg"> 2020 ACG
          Reklámügynökség Kft.
          All
          rights reserved.
        </div>
        <div class="footer-button-container">
          <button type="button" id="CookieBannerSettings" @click="showCookieBanner()" class="text--footer text-white text-decoration-none margin-right--large">Cookie Policy Settings</button>
          <a href="/" class="text--footer text-white text-decoration-none margin-right--large">Privacy Policy</a>
          <!-- <a  href="/" class="text-footer text-white text-decoration-none">Terms and Conditions</a> -->
        </div>
      </div>
    </div>
    <CookiePolicy :changeCookieSettings="changeCookieSettings" />
  </div>
</template>

<script>
import CookiePolicy from '@/components/CookiePolicy.vue'

export default {
  name: "Footer",
  components:{
    CookiePolicy
  },
  data(){
    return{
      changeCookieSettings:false
    }
  },
  methods:{
    showCookieBanner(){
      this.changeCookieSettings = true
    }
  }
}
</script>
