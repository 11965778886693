import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

const timeStamp = Math.floor(Date.now() / 1000)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    questions: []
  },
  mutations: {
    getQuestions(state, questions) {
      state.questions = questions;
    }
  },
  actions: {
    getQuestions({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'questions.json?' + timeStamp).then(response => {
          commit("getQuestions", response.data)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    }
  }
})
