<template>
  <div id="powerfulCollaboration" class="container-xl collaboration-bg">
    <h1 class="h1-margin-bottom--large h1-margin-top--small">Powerful <span class="text-blue">collaboration </span>features.
    </h1>
    <div>
      <div class="row m-md--auto h1-margin-bottom-bottom-collaboration vcard vcard-360 col-md-12 col-lg-10">
        <div class="col-md-6 v-card-img-container">
          <img class="img-fluid" src="../assets/images/01.png"/>
        </div>
        <div class="col-md-6 padding-left--col align-self-center">
          <div class="d-flex margin-bottom--medium align-items-center">
            <img class="margin-right--small" src="../assets/icons/timeline.svg">
            <h2>Timeline</h2>
          </div>
          <span class="text">Review the updates on your campaign quickly on the history log.</span>
        </div>
      </div>

      <div class="row  m-md--auto  h1-margin-bottom-bottom-collaboration vcard vcard-360 m-auto--left col-md-12 col-lg-10">
        <div class="col-md-6 resp-none-display--sm v-card-img-container">
          <img class="" src="../assets/images/02.png"/>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="d-flex margin-bottom--medium align-items-center">
            <img class="margin-right--small" src="../assets/icons/timeline.svg">
            <h2>Comment</h2>
          </div>
          <span class="text ">Leave comments and discuss the changes directly on the creative.</span>
        </div>
        <div class="col-md-6 resp-display--sm v-card-img-container">
          <img class=" " src="../assets/images/02.png"/>
        </div>
      </div>

      <div class="row  m-md--auto  h1-margin-bottom-bottom-collaboration vcard vcard-360 col-md-12 col-lg-10">
        <div class="col-md-6 v-card-img-container">
          <img class="" src="../assets/images/03.png"/>
        </div>
        <div class="col-md-6 padding-left--col align-self-center">
          <div class="d-flex margin-bottom--medium align-items-center">
            <img class="margin-right--small" src="../assets/icons/timeline.svg">
            <h2>Versions</h2>
          </div>
          <span class="text">Toggle between the latest and the previous versions of your creative to see the changes easily.</span>
        </div>
      </div>

      <div class="row  m-md--auto  vcard m-auto--left vcard-360 col-md-12 col-lg-10">
        <div class="col-md-6 resp-none-display--sm v-card-img-container">
          <img class=" " src="../assets/images/04.png"/>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="d-flex margin-bottom--medium align-items-center">
            <img class="margin-right--small" src="../assets/icons/timeline.svg">
            <h2>Flow management</h2>
          </div>
          <span
              class="text">Track the status of your creatives to see at a glance, which one is approved and which one you still have work with.</span>
        </div>
        <div class="col-md-6 resp-display--sm v-card-img-container">
          <img class="" src="../assets/images/04.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PowerfulCollaboration"
}
</script>

<style scoped>

</style>