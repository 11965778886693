<template>
  <div id="app">
    <NavBar />
    <router-view/>
    <Footer/>
    
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";


export default {
  name: 'App',
  components: {
    Footer,
    NavBar    
  },
  watch:{
    $route(){
        window.dataLayer.push({
            'event': 'eventNavigation',
            'category': '',
            'action': '',
            'label': ''
        });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
