<template>
  <b-navbar toggleable="lg" class="bg-navbar">
    <div class="container-fluid container-xl">
      <div class="navbar__header">
        <router-link :to="'/'"><b-navbar-brand><img src="@/assets/images/main-logo.jpg" alt="Approvee.ly" class="w-100" /></b-navbar-brand></router-link>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </div>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="m-auto">          
          <li class="nav-item"> 
            <router-link class="text-decoration-none nav-link" :to="'/'">
              <span class="nav-link padding-none">Home</span>
            </router-link>
          </li>         
          <li class="nav-item"> 
            <router-link class="text-decoration-none nav-link" :to="'/'" v-scroll-to="'#creativeWork'">
              <span class="nav-link padding-none">Why us?</span>
            </router-link>
          </li>         
          <li class="nav-item"> 
            <router-link class="text-decoration-none nav-link" :to="'/'" v-scroll-to="'#powerfulCollaboration'">
              <span class="nav-link padding-none">Features</span>
            </router-link>
          </li>         
          <li class="nav-item"> 
            <router-link class="text-decoration-none nav-link" :to="'/'" v-scroll-to="'#pricing'">
              <span class="nav-link padding-none">Price</span>
            </router-link>
          </li>         
          <li class="nav-item"> 
            <router-link class="text-decoration-none nav-link question-link" :to="'/questions'">
              <span class="nav-link padding-none">FAQ</span>
            </router-link>
          </li>          
          <li class="nav-item"> 
            <router-link class="text-decoration-none nav-link" :to="'/'" v-scroll-to="'#contact'">
              <span class="nav-link padding-none">Contact</span>
            </router-link>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="">          
            <div class="resp-row d-flex">
              <!-- <div class="d-flex ">
                <img class="icon align-self-center" src="../assets/icons/lock.svg"/>
                <a size="sm" class="text text--menu margin-right--large" type="submit">Sign in</a>
              </div> -->
              <router-link :to="'/'" v-scroll-to="'#contact'" class="btn btn-menu border-0 margin-top--medium margin-bottom--medium" type="submit">Start free 14 day trial <img class="icon-chev" src="../assets/icons/chevron-right.svg"/></router-link>
            </div>          
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>

</style>