<template>
  <div id="contact" class="container-xl  h1-margin-bottom--small">
    <div class="vcard vcard--question vcard--contact">

      <b-modal ok-only no-close-on-esc hide-header-close id="modal-1">
        <div class="d-block text" style="text-align:center">
          <strong>Thank you for your message! </strong>
          <br/>We will get back to you as soon as possible.
        </div>
      </b-modal>

      <div class="row">
        <div class="col-md-7 text-md-start">
          <img class="mw-100 margin-bottom--large img-contact" src="../assets/images/contact-image.png"/>
          <div>
            <!--<div class="margin-bottom--large margin-top--small">
              <div class="d-flex margin-bottom--small">
                <img class=" margin-right--small" src="../assets/icons/phone.svg">
                <h2>Phone number</h2>
              </div>
              <span class="text weight-500"><a href="tel:+36304441100">+36 30/4441-100</a></span>
            </div>
            <div class="margin-bottom--large">
              <div class="d-flex margin-bottom--small">
                <img class="margin-right--small" src="../assets/icons/email.svg">
                <h2>Email Address</h2>
              </div>
              <span class="text weight-500"><a href="mailto:approvee.ly@acg.hu">approvee.ly@acg.hu</a></span>
            </div>
            <div class="margin-bottom--large">
              <div class="d-flex margin-bottom--small">
                <img class="margin-right--small" src="../assets/icons/address.svg">
                <h2>Address</h2>
              </div>
              <span class="text weight-500">Budapest, Henger u. 2/b, 1027</span>
            </div>-->
          </div>
        </div>
        <div class="text-md-start col-md-5 align-content-end flex-column d-flex">
          <div class="d-flex margin-bottom--small">
            <img class="margin-right--small" src="../assets/icons/get-in-touch.svg">
            <h2>Get in touch</h2>
          </div>
          <div class="margin-bottom--large text weight-500 text-contact-grey">For more information or to start your free 14 day trial please contact us.
          </div>
          <div class="email-confirm-row input--info">
            <validation-observer v-slot="{ handleSubmit }">
              <form
                  @submit.prevent="handleSubmit(formSubmit)">
                <div class="margin-bottom--medium">
                  <ValidationProvider
                      rules="required"
                      v-slot="{ failedRules }"
                  >
                    <div class="margin-bottom--small input-label">Name</div>
                    <input
                        v-model="form.name"
                        type="text"
                        placeholder="Enter your name"
                        class="form-control"
                        name="Name"
                        id="name"
                    />
                    <span class="error">{{
                        getErrorMessage(
                            "name",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                  </ValidationProvider>
                </div>
                <div class="margin-bottom--medium">
                  <ValidationProvider
                      rules="required"
                      v-slot="{ failedRules }"
                  >
                    <div class="margin-bottom--small input-label">Phone Number</div>
                    <input
                        v-model="form.phone"
                        type="text"
                        placeholder="Enter your phone number"
                        class="form-control"
                        name="Phone Number"
                        id="phone"
                    />
                    <span class="error">{{
                        getErrorMessage(
                            "phone",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                  </ValidationProvider>
                </div>
                <div class="margin-bottom--medium">
                  <ValidationProvider
                      rules="required"
                      v-slot="{ failedRules }"
                  >
                    <div class="margin-bottom--small input-label">Company</div>
                    <input
                        v-model="form.company"
                        type="text"
                        placeholder="Enter your company name"
                        class="form-control"
                        name="Company"
                        id="company"
                    />
                    <span class="error">{{
                        getErrorMessage(
                            "company",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                  </ValidationProvider>
                </div>
                <div class="margin-bottom--large">
                  <ValidationProvider
                      rules="required"
                      v-slot="{ failedRules }"
                  >
                    <div class="margin-bottom--small input-label">Message</div>
                    <textarea
                        v-model="form.message"
                        type="text"
                        placeholder="Enter your message"
                        class="form-control"
                        name="Message"
                        id="message"
                    />
                    <span class="error">{{
                        getErrorMessage(
                            "message",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                  </ValidationProvider>
                </div>
                <div class="margin-bottom--large">
                  <ValidationProvider
                      rules="required|acceptConditions"
                      v-slot="{ failedRules }"
                  >
                    <b-form-checkbox
                      id="condition"
                      v-model="form.condition"
                      name="condition"
                      value="1"
                      unchecked-value="0"
                    >
                      I accept the <a :href="baseUrl + 'documents/PrivacyPlicy_EN.pdf'" target="_blank">Privacy Policy</a>
                    </b-form-checkbox>
                    <span class="error">{{
                        getErrorMessage(
                            "condition",
                            Object.entries(failedRules)[0]
                        )
                      }}</span>
                  </ValidationProvider>
                </div>                

                <div class="text-center">
                  <button type="submit" class="btn btn-primary--contact">send the message<img class="icon-contact"
                                                                                              src="../assets/icons/sending.svg"/>
                  </button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import {ValidationObserver} from "vee-validate";
import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";
import {required, email, confirmed} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import errorMessages from "@/assets/errorMessages.json";

// Import date picker css

setInteractionMode("passive");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Nem elegendő karakterszám.",
});
extend("minAz", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Az azonosító szám formátuma nem megfelelő.",
});
extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  }  
});
extend("email", {
  ...email,
  message: "Az e-mail mező csak érvényes e-mail címet tartalmazhat.",
});
extend("required", {
  ...required,
  message: "{_field_} megadása kötelező.",
});
extend("confirmed", {
  ...confirmed,
  message: "Nem megegyező email cím.",
});

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      show: false,
      form: {
        name: "",
        phone: "",
        company: "",
        message: "",
        condition: ""        
      },
      formSubmitted: false
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    showModal() {
      this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    },
    toggleModal() {
      this.$root.$emit('bv::toggle::modal', 'modal-1', '#btnToggle')
    },
    getErrorMessage(fieldName, violation) {
      return violation
          ? (errorMessages[fieldName] &&
          errorMessages[fieldName][violation[0]]) ??
          violation[1]
          : undefined;
    },
    // findPos(obj) {
    //   var curtop = 0;
    //   if (obj.offsetParent) {
    //     do {
    //       curtop += obj.offsetTop;
    //     } while ((obj = obj.offsetParent));
    //     return [curtop];
    //   }
    // },
    formSubmit() {
      const _this = this;
      axios
          .post(process.env.VUE_APP_API_URL, {
            name: this.form.name,
            phone: this.form.phone,
            company: this.form.company,
            message: this.form.message,
            condition: this.form.condition            
          })
          .then(() => {
            _this.showModal();
            _this.resetForm();
            // window.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    resetForm() {
      this.form.name = "";
      this.form.phone = "";
      this.form.company = "";
      this.form.message = "";
      this.form.condition = "";      
      // window.reload();
    },
  },
  cancel() {
    this.formSubmitted = false

  }
};

</script>

<style scoped>

</style>