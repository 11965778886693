<template>
  <div id="pricing" class="container-xl">
    <div class="bg-creative--pricing bg-creative h1-margin-top">
      <h1 class="h1-margin-bottom"><span class="text-blue">Simple pricing.</span> Full accessibility.</h1>
      <div class="row resp-row align-items-center">

       

        <div class="vcard vcard--pricing vcard--pricing--left card-height col-md-4">
          <div class="d-flex margin-bottom--large align-items-center">
            <img class="margin-right--small" src="../assets/icons/person.svg">
            <h2>Basic</h2>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">5 Uploaders (+1 for 3€)</span>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">5 Commenters (+5 for 3€)</span>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">5 GB Storage (+5 GB for 2€)</span>
          </div>
          <div class="margin-bottom--large d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">Full functionality</span>
          </div>
          <div class="margin-bottom--small margin-top--small text-price text-blue d-flex justify-content-center">34.99 €<span
              class="opacity-50"> / mo</span></div>
          <a href="/#contact" class="btn btn-primary--contact">get in touch <img class="icon-chev" src="../assets/icons/chevron-right.svg"/></a>
        </div>

        <div
            class="resp-display v-card vcard--blue text-white text-lg-start vcard--blue--pricing card-height--big col-md-4">
          <div class="d-flex margin-bottom--medium align-items-center">
            <img class="margin-right--small" src="../assets/icons/pro.svg">
            <h2>Pro</h2>
            <div class="popular-button d-flex">
              <img class="margin-right--small popular-icon" src="../assets/icons/stars.svg">
              <span class="popular-button--text">Most popular</span>
            </div>
          </div>
          <!-- <div class="margin-bottom--large text text-white">We give you <strong>15% discount</strong> per<br/> access.</div> -->
          <div class="d-flex margin-bottom--small">
            <img class="icon--20" src="../assets/icons/check-white.svg" alt="pipe"/>
            <span class="text-price--small text-white">10 Uploaders (+1 for 2€)</span>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/check-white.svg" alt="pipe"/>
            <span class="text-price--small text-white">20 Commenters (+5 for 2€)</span>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/check-white.svg" alt="pipe"/>
            <span class="text-price--small text-white">50 GB Storage (+10 GB for 5€)</span>
          </div>
          <div class="margin-bottom--large d-flex">
            <img class="icon--20" src="../assets/icons/check-white.svg" alt="pipe"/>
            <span class="text-price--small text-white">Full functionality</span>
          </div>
          <div class="margin-bottom--small margin-top--small text-price d-flex justify-content-center">
            49.99 €<span class="opacity-50"> / mo</span></div>
          <a href="/#contact" class="btn btn-white btn-primary--contact">get in touch 
          <span class="icon"></span> </a>
        </div>

        <div class="col-md-4 vcard vcard--pricing vcard--pricing--right card-height">
          <div class="d-flex margin-bottom--large align-items-center">
            <img class="margin-right--small" src="../assets/icons/building.svg">
            <h2>Enterprise</h2>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">30 Uploaders (+5 for 3€)</span>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">70 Commenters (+10 for 2€)</span>
          </div>
          <div class="margin-bottom--small d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">150 GB storage (+20 GB for 5€)</span>
          </div>
          <div class="margin-bottom--large d-flex">
            <img class="icon--20" src="../assets/icons/pipe.svg" alt="pipe"/>
            <span class="text-price--small">Full functionality</span>
          </div>
          <div class="margin-bottom--small margin-top--small text-price text-blue d-flex justify-content-center">
            159.99 €<span class="opacity-50"> / mo</span></div>
          <a href="/#contact" class="btn btn-primary--contact">get in touch <img class="icon-chev" src="../assets/icons/chevron-right.svg"/></a>
        </div>
      </div>
      <p class="more-text">Do you have custom needs? We’ll find a solution for you! <a href="/#contact">Get in touch!</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing"
}
</script>

<style scoped>

</style>