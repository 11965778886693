<template>
  <div id="thereIsMore" class="container-xl thereIsMore-bg">
    <h1 class="h1-margin-bottom h1-margin-top"><span class="text-blue">And</span> there is <span
        class="text-blue">more.</span></h1>
    <div class="row justify-content-center there-is-more-card-container">
      <div class="col-md-6 col-xl-4">
        <div class="vcard vcard--small">
          <div class="d-flex margin-bottom--small">
            <div class="margin-right--small thereIsMore-icon--setUp"/>
            <h3 class="text weight-500">Assignment</h3>
          </div>
          <span class="text-gray">Assign the right people to the right board so everyone will be notified, and no one will be spammed about the changes.</span>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="vcard vcard--small">
          <div class="d-flex margin-bottom--small">
            <div class="margin-right--small thereIsMore-icon--share"/>
            <h3 class="text weight-500">Share</h3>
          </div>
          <span class="text-gray">Download and email or print out the original files if you would like to share them with your colleagues.</span>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="vcard vcard--small">
          <div class="d-flex margin-bottom--small">
            <div class="margin-right--small thereIsMore-icon--everything"/>
            <h3 class="text weight-500">All in one</h3>
          </div>
          <span class="text-gray">Manage all your clients and campaigns at one place without any limitations to enhance your workflow and spare precious time. </span>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="vcard vcard--small">
          <div class="d-flex margin-bottom--small">
            <div class="margin-right--small thereIsMore-icon--separate"/>
            <h3 class="text weight-500">Feedback in rounds</h3>
          </div>
          <span class="text-gray">Comment all creatives first then send the feedback altogether to encourage clients to organize and collect their comments.</span>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="vcard vcard--small">
          <div class="d-flex margin-bottom--small">
            <div class="margin-right--small thereIsMore-icon--manage"/>
            <h3 class="text weight-500">Search</h3>
          </div>
          <span class="text-gray">Search for campaigns, boards, and creatives to find what you need as quickly as possible.
          </span>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="vcard vcard--small">
            <div class="d-flex margin-bottom--small">
              <div class="margin-right--small thereIsMore-icon--search"/>
              <h3 class="text weight-500">File formats</h3>
            </div>
            <span class="text-gray">All the most used file formats are supported in approve.ly be it image, video, or html banner.
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThereIsMore"
}
</script>

<style scoped>

</style>