import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import router from './config/router.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/css/main.min.css'
import 'bootstrap'
import store from './config/store.js'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

//cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.use(BootstrapVue)
Vue.config.productionTip = false


Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
