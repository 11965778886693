var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-xl  h1-margin-bottom--small",attrs:{"id":"contact"}},[_c('div',{staticClass:"vcard vcard--question vcard--contact"},[_c('b-modal',{attrs:{"ok-only":"","no-close-on-esc":"","hide-header-close":"","id":"modal-1"}},[_c('div',{staticClass:"d-block text",staticStyle:{"text-align":"center"}},[_c('strong',[_vm._v("Thank you for your message! ")]),_c('br'),_vm._v("We will get back to you as soon as possible. ")])]),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"text-md-start col-md-5 align-content-end flex-column d-flex"},[_vm._m(1),_c('div',{staticClass:"margin-bottom--large text weight-500 text-contact-grey"},[_vm._v("For more information or to start your free 14 day trial please contact us. ")]),_c('div',{staticClass:"email-confirm-row input--info"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formSubmit)}}},[_c('div',{staticClass:"margin-bottom--medium"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('div',{staticClass:"margin-bottom--small input-label"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter your name","name":"Name","id":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage( "name", Object.entries(failedRules)[0] )))])]}}],null,true)})],1),_c('div',{staticClass:"margin-bottom--medium"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('div',{staticClass:"margin-bottom--small input-label"},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter your phone number","name":"Phone Number","id":"phone"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage( "phone", Object.entries(failedRules)[0] )))])]}}],null,true)})],1),_c('div',{staticClass:"margin-bottom--medium"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('div',{staticClass:"margin-bottom--small input-label"},[_vm._v("Company")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.company),expression:"form.company"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter your company name","name":"Company","id":"company"},domProps:{"value":(_vm.form.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "company", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage( "company", Object.entries(failedRules)[0] )))])]}}],null,true)})],1),_c('div',{staticClass:"margin-bottom--large"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('div',{staticClass:"margin-bottom--small input-label"},[_vm._v("Message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter your message","name":"Message","id":"message"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage( "message", Object.entries(failedRules)[0] )))])]}}],null,true)})],1),_c('div',{staticClass:"margin-bottom--large"},[_c('ValidationProvider',{attrs:{"rules":"required|acceptConditions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
return [_c('b-form-checkbox',{attrs:{"id":"condition","name":"condition","value":"1","unchecked-value":"0"},model:{value:(_vm.form.condition),callback:function ($$v) {_vm.$set(_vm.form, "condition", $$v)},expression:"form.condition"}},[_vm._v(" I accept the "),_c('a',{attrs:{"href":_vm.baseUrl + 'documents/PrivacyPlicy_EN.pdf',"target":"_blank"}},[_vm._v("Privacy Policy")])]),_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.getErrorMessage( "condition", Object.entries(failedRules)[0] )))])]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary--contact",attrs:{"type":"submit"}},[_vm._v("send the message"),_c('img',{staticClass:"icon-contact",attrs:{"src":require("../assets/icons/sending.svg")}})])])])]}}])})],1)])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-7 text-md-start"},[_c('img',{staticClass:"mw-100 margin-bottom--large img-contact",attrs:{"src":require("../assets/images/contact-image.png")}}),_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex margin-bottom--small"},[_c('img',{staticClass:"margin-right--small",attrs:{"src":require("../assets/icons/get-in-touch.svg")}}),_c('h2',[_vm._v("Get in touch")])])}]

export { render, staticRenderFns }