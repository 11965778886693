import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from "@/views/MainPage";
import Questions from "@/views/Questions";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/questions',
    name: 'Questions',
    component: Questions
  },
  {
    path: "*",
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})


// const router = new VueRouter({
//   routes
// })


export default router
