<template>
    <div class="video-player" v-show="showVideoLayer">
        <div class="video-player__inner"                         
            ref="videoPlayerWrap"
            @click="videoPlay" 
        >            
            <video ref="videoPlayer">
                <source :src="video" type="video/mp4">
            </video>                                   
        </div>   
        <button type="button" class="btn-close" @click="closeVideoPlayer()"></button>
    </div>
</template>

<script>
export default {
    props:['video', 'poster', 'showVideoLayer'],     
    watch:{
        showVideoLayer(newValue){
            let _this = this

            if (newValue === true){
                _this.videoPlay()
            }

        }
    },
    methods:{
        videoPlay(){    
            let _this = this                            

            if (_this.$refs.videoPlayer.paused){
                _this.$refs.videoPlayer.play()
                _this.isVideoPlay = true
            } else {
                _this.$refs.videoPlayer.pause()
                _this.isVideoPlay = false
                _this.showVideo = true
            }            
            
        },
        closeVideoPlayer(){
            this.$parent.showVideoLayer = false
            this.$refs.videoPlayer.pause()
            this.isVideoPlay = false
            this.showVideo = true
        } 
    }
}
</script>