<template>
  <div>
    <Hero/>
    <CreativeWork/>
    <PowerfulCollaboration/>
    <ThereIsMore/>
    <Pricing/>
    <Contact/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import CreativeWork from "@/components/CreativeWork";
import PowerfulCollaboration from "@/components/PowerfulCollaboration";
import ThereIsMore from "@/components/ThereIsMore";
import Pricing from "@/components/Pricing";
import Contact from "@/components/Contact";

export default {
  name: "MainPage",
  components: {
    Contact,
    Pricing,
    ThereIsMore,
    PowerfulCollaboration,
    CreativeWork,
    Hero,
  }
}
</script>

<style scoped>

</style>